import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from '../core/core.module';
import {GestionProductionRoutingModule} from './gestion-production-routing.module';
import {GestionProductionRootComponent} from './gestion-production-root/gestion-production-root.component';
import {PpComponent} from './pp/pp.component';
import {SharedModule} from '../shared/shared.module';
import {PlansDeProductionComponent} from './pp/plans-de-production/plans-de-production.component';
import {PrintSettingsComponent} from './pp/plans-de-production/print-settings/print-settings.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {
  BulkEditionDetailsDialogComponent
} from './pp/plans-de-production/grid-details-pp/bulk-edition-details-dialog/bulk-edition-details-dialog.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortiesProductionComponent} from './pp/sorties-production/sorties-production.component';
import {
    DxAccordionModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxListModule,
    DxLookupModule, DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule,
    DxTabPanelModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxToolbarModule, DxTreeListModule,
    DxTreeViewModule
} from 'devextreme-angular';

import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {SelectButtonModule} from 'primeng/selectbutton';
import {
  InitializationPlanDeProductionComponent
} from "./pp/plans-de-production/initialization/initialization-plan-de-production.component";
import {
  SelectionFiltersPlanProductionComponent
} from "./pp/plans-de-production/selection-filters/selection-filters-plan-production.component";
import {PlanProductionComponent} from "./pp/plans-de-production/plan-production/plan-production.component";
import {OrganisationsTourneesComponent} from "./organisations-tournees/organisations-tournees.component";
import {
  OrganisationTourneeDialogComponent
} from "./organisations-tournees/dialog-edition/organisation-tournee-dialog-edition.component";
import {
  TabsErrorsOrgTourneeComponent
} from "./organisations-tournees/dialog-edition/tabs/errors/tabs-errors-org-tournee.component";
import {
  TabsWarningsOrgTourneeComponent
} from "./organisations-tournees/dialog-edition/tabs/warnings/tabs-warnings-org-tournee.component";
import {PrintSortiesProductionSettingsComponent} from "./pp/sorties-production/print-sorties-production-settings/print-sorties-production-settings.component";
import {NgxPaginationModule} from "ngx-pagination";


@NgModule({
    imports: [
        CommonModule,
        GestionProductionRoutingModule,
        SharedModule,
        GestionProcessusModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        MenuModule,
        SelectButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        DxTagBoxModule,
        DxPopupModule,
        DxLookupModule,
        DxTextBoxModule,
        DxTreeViewModule,
        DxRadioGroupModule,
        DxDateBoxModule,
        DxTabPanelModule,
        DxToolbarModule,
        DxListModule,
        DxDropDownButtonModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxTreeListModule,
        DxScrollViewModule,
        DxButtonGroupModule,
        DxAccordionModule,
        NgxPaginationModule
    ],
  entryComponents: [],
  declarations: [
    GestionProductionRootComponent,
    PpComponent,
    PlansDeProductionComponent,
    PrintSettingsComponent,
    SortiesProductionComponent,
    BulkEditionDetailsDialogComponent,
    InitializationPlanDeProductionComponent,
    SelectionFiltersPlanProductionComponent,
    PlanProductionComponent,
    OrganisationsTourneesComponent,
    OrganisationTourneeDialogComponent,
    TabsErrorsOrgTourneeComponent,
    TabsWarningsOrgTourneeComponent,
    PrintSortiesProductionSettingsComponent
  ]
})
export class GestionProductionModule {
}
